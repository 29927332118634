@import "utils/colors";
@import "utils/rem-calc";
@import "utils/media-queries";

body.login {
  position: relative;
  background: $color__login-background;
  color: $color__black;
  font-size: rem-calc(16px);
  line-height: rem-calc(20px);

  .login-complement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    margin: -20px -20px 20px;
    color: $color__white;
    font-size: rem-calc(20px);
    order: 1 !important;
    background: $color__login-background;
    background-image: url("../img/login-pattern.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint('large') {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50% - 200px);
      height: calc(100% - 100px);
      margin: 0;
      padding: 50px 100px;
    }

    &__blogname {
      display: flex;
      align-items: center;
      font-weight: 600;

      &:before {
        content: '';
        display: block;
        height: 50px;
        margin-right: 15px;
        border: 2px solid $color__white;
      }
    }

    &__title {
      font-size: rem-calc(40px);
      font-weight: 600;
      margin-top: auto;
    }

    &__powered-by {
      font-size: rem-calc(14px);

      @include breakpoint('large') {
        display: flex;
        align-items: center;
        margin-top: auto;
      }

      &:before {
        content: '';
        display: block;
        height: rem-calc(1px);
        width: 50%;
        background-color: $color__white;
        margin-top: 15px;
        margin-bottom: 15px;

        @include breakpoint('large') {
          margin-right: rem-calc(15px);
        }
      }

      a {
        color: $color__white;
        text-decoration: none;
        margin-left: rem-calc(5px);
      }
    }
  }

  #login {
    background-color: $color__white;
    padding: 20px 20px !important;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 40px);

    @include breakpoint('large') {
      width: calc(50% - 200px);
      min-height: calc(100% - 100px);
      padding: 50px 100px !important;
    }

    > * {
      order: 2;
    }
  }

  form {
    margin-top: 0;
    padding: 25px 5px;
    border: unset;
    box-shadow: unset;

    p.submit {
      display: block;
      margin-top: 30px !important;

      input[type=submit] {
        float: none;
        width: 100%;
        border-radius: 0;
        padding: 10px 15px !important;
        font-size: rem-calc(18px) !important;
        line-height: rem-calc(20px) !important;
        background-color: $color__login-background;
        border: 1px solid $color__login-background;

        &:hover, &:focus {
          background-color: $color__white;
          color: $color__login-background !important;
        }
      }
    }
  }

  label {
    font-size: rem-calc(18px);
    line-height: rem-calc(25px);
    margin-bottom: rem-calc(5px);
  }

  .input {
    font-size: rem-calc(18px);
    line-height: rem-calc(25px);
    border: unset;
    border-radius: 0;
    padding: 10px 15px;
    margin: 0;
    margin-bottom: rem-calc(15px);
    background-color: $color__light-gray;
  }

  input[type=checkbox], input[type=radio] {
    background-color: $color__light-gray !important;
    border: unset;
    border-radius: 0;
    color: $color__black;
    width: 20px;
    height: 20px;
    box-shadow: unset;

    &:checked::before {
      margin: 0;
    }
  }

  .forgetmenot {
    float: none;

    label {
      font-size: rem-calc(16px);
    }
  }

  #backtoblog, #nav {
    font-size: rem-calc(16px);
    padding: 0;
    margin-top: 25px;
  }

  #backtoblog {
    a {
      background-color: $color__login-background;
      color: $color__white !important;
      padding: 5px 15px;
      font-size: rem-calc(14px);
      border: 1px solid $color__login-background;

      &:hover, &:focus {
        background-color: $color__white;
        color: $color__login-background !important;
      }
    }
  }

  h1 a {
    background-image: url("../img/login-locked.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
