$color__white: #FFFFFF;
$color__light-gray: #EEEEEE;
$color__soft-gray: #D0D0D0;
$color__medium-gray: #A3A3A3;
$color__gray: #777777;
$color__hard-gray: #4E4E4E;
$color__very-hard-gray: #292929;
$color__black: #000000;

$color__admin-development: #721C24;
$color__admin-staging: #856404;
$color__admin-production: #155724;

$color__login-background: #00ACB3;

$color__cookie-text: #0E1358;
$color__cookie-background-button: #0E1358;
$color__cookie-background-button-allow: #9CD9D9;

$color__woocommerce-color-text: #322A7D;
$color__woocommerce-color-background: #F7F8FA;
$color__woocommerce-color-background-bis: #FFFFFF;
$color__woocommerce-color-background-container: #FFFFFF;
$color__woocommerce-color-border-table: #98A7C9;
$color__woocommerce-color-background-button: #E0E4EB;
$color__woocommerce-color-button: #322A7D;
$color__woocommerce-color-background-button--hover: #322A7D;
$color__woocommerce-color-button--hover: #FFFFFF;
