$small: 0px !default;
$medium: 768px !default;
$large: 1024px !default;
$container: 1280px !default;
$xlarge: 1440px !default;
$xxlarge: 1920px !default;

$breakpoints: (
        small: $small,
        medium: $medium,
        large: $large,
        container: $container,
        xlarge: $xlarge,
        xxlarge: $xxlarge,
) !default;

@mixin breakpoint($size) {
  $value: $size;

  @if (type_of($size) == "string") {
    $value: map-get($breakpoints, $size);
  }

  @if ($value > 0) {
    @media screen and (min-width: $value) {
      @content;
    }
  } @else {
    @content;
  }
}
